@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Kiwi+Maru:wght@500&display=swap");
ins, mark {
  color: #000;
  background-color: #ff9;
}

.clear, hr {
  clear: both;
}

a, hr, img {
  padding: 0;
  margin: 0;
}

abbr, address, article, aside, audio, b, blockquote, body, canvas, caption, cite, code, dd, del, details, dfn, div, dl, dt, em, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, p, pre, q, samp, section, small, span, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  line-height: 100%;
  letter-spacing: 1px;
}

body {
  line-height: 100%;
  font-family: "Avenir Next", Verdana, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "游ゴシック", "Yu Gothic", "メイリオ", Meiryo, Osaka, sans-serif;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:after, blockquote:before, q:after, q:before {
  content: "";
  content: none;
}

a {
  font-size: 100%;
  vertical-align: baseline;
  background: 0 0;
}

ins {
  text-decoration: none;
}

mark {
  font-style: italic;
  font-weight: 700;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input, select {
  vertical-align: middle;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.t-left {
  text-align: left !important;
}

.t-center {
  text-align: center !important;
}

.t-right {
  text-align: right !important;
}

.f-left {
  float: left !important;
}

.f-right {
  float: right !important;
}

hr {
  border: none;
}

a img:hover {
  -moz-opacity: 0.7;
  opacity: 0.7;
  filter: alpha(opacity=70);
}

img {
  width: auto;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

* {
  box-sizing: border-box;
}
*:focus {
  outline: none;
}

body {
  background: url(../images/bg-main.jpg) repeat top left;
}

body,
p,
ul,
li,
dl,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
address {
  color: #333;
  font: 400 normal 16px/166% "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Verdana, "メイリオ", Meiryo, Osaka, "Avenir Next", "游ゴシック", "Yu Gothic", sans-serif;
  letter-spacing: 1px;
  -moz-font-feature-settings: "palt";
  -webkit-font-feature-settings: "palt";
  font-feature-settings: "palt";
  margin: 0;
}

a {
  color: #e38f35;
  text-decoration: underline;
  transition: all 0.2s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a:hover {
  text-decoration: none;
  opacity: 0.7;
}

.t_left {
  text-align: left !important;
}

.t_right {
  text-align: right !important;
}

.t_center {
  text-align: center !important;
}

@media all and (max-width: 599px) {
  .view_pc {
    display: none;
  }
}

.view_sp {
  display: none;
}
@media all and (max-width: 599px) {
  .view_sp {
    display: block;
  }
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.flex.wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flex.center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.container {
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
}
@media all and (max-width: 820px) {
  .container {
    padding: 0 15px;
  }
}

.header {
  background: url(../images/bg-head.png) repeat-x top -20px left;
}
@media all and (max-width: 820px) {
  .header {
    margin-bottom: 20px;
  }
  .header__logo {
    width: 160px;
  }
}
.header__inner {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  gap: 10px;
  padding: 15px 0;
}
@media all and (max-width: 820px) {
  .header__inner {
    padding: 15px;
  }
}
.header__menu {
  gap: 0 16px;
}
.header__menu a {
  display: block;
  height: 110px;
  overflow: hidden;
}
.header__menu a:hover {
  opacity: 1;
}
.header__menu a:hover img {
  opacity: 1;
  margin-top: -110px;
}
.header__info {
  width: 278px;
}
.header__info-txt {
  font-size: 13px;
  margin-bottom: 4px;
  text-align: center;
}
.header__tel {
  margin-bottom: 5px;
}
.header__time {
  font-size: 13px;
  text-align: center;
}
.header__nav {
  margin: -10px 0 10px;
  animation-duration: 0.1s;
}
@media all and (max-width: 820px) {
  .header__nav {
    display: none;
    background-color: rgba(194, 163, 100, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100vh;
    padding-top: 80px;
    z-index: 1000;
  }
}
.header__nav-inner {
  background: url(../images/bg-nav.png) no-repeat center center;
  background-size: cover;
  width: 1040px;
  height: 68px;
  gap: 20px 30px;
  -webkit-justify-content: center;
  justify-content: center;
}
@media all and (max-width: 820px) {
  .header__nav-inner {
    background: none;
  }
}
.header .menu-item a {
  color: #333;
  font-family: "Kiwi Maru", serif;
  font-size: 18px;
  letter-spacing: 0;
  text-decoration: none;
  text-shadow: 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff;
  transition: all 0.2s;
}
.header .menu-item a:hover {
  opacity: 1;
  background: -webkit-linear-gradient(transparent 80%, #e38f35 80%);
  background: -o-linear-gradient(transparent 80%, #e38f35 80%);
  background: linear-gradient(transparent 80%, #e38f35 80%);
}
.header .menu-item.current-menu-item a {
  background: -webkit-linear-gradient(transparent 80%, #e38f35 80%);
  background: -o-linear-gradient(transparent 80%, #e38f35 80%);
  background: linear-gradient(transparent 80%, #e38f35 80%);
}

#menuButton {
  display: block;
  height: 40px;
  position: relative;
  z-index: 1010;
  border: solid 2px #333;
  border-radius: 3px;
  text-decoration: none;
  color: #333;
  font: 700 16px/38px arial, Verdana;
  padding: 0 40px 0 10px;
  outline: none;
}
#menuButton:hover {
  opacity: 1;
}
#menuButton span {
  display: block;
  background: #333;
  width: 24px;
  height: 2px;
  border-radius: 2px;
  position: absolute;
  right: 9px;
  transition: all 0.4s;
}
#menuButton span:first-child {
  top: 10px;
}
#menuButton span:nth-child(2) {
  margin-top: -1px;
  top: 50%;
}
#menuButton span:last-child {
  bottom: 10px;
}
#menuButton.active {
  color: #fff;
  border-color: #fff;
}
#menuButton.active span {
  background: #fff;
}
#menuButton.active span:first-child {
  -webkit-transform: translateY(8px) rotate(45deg);
  -moz-transform: translateY(8px) rotate(45deg);
  transform: translateY(8px) rotate(45deg);
  top: 9px;
}
#menuButton.active span:nth-child(2) {
  opacity: 0;
}
#menuButton.active span:last-child {
  -webkit-transform: translateY(-8px) rotate(-45deg);
  -moz-transform: translateY(-8px) rotate(-45deg);
  transform: translateY(-8px) rotate(-45deg);
  bottom: 9px;
}

#pagetop_button {
  position: fixed;
  right: 20px;
  bottom: 30px;
  z-index: 9999;
}
@media all and (max-width: 1040px) {
  #pagetop_button {
    display: none !important;
  }
}
@media all and (max-width: 599px) {
  #pagetop_button {
    display: none !important;
  }
}

.footer {
  background: url(../images/bg-foot.png) repeat-x left top;
  padding: 100px 0 30px;
}
.footer__inner {
  -webkit-justify-content: center;
  justify-content: center;
}
.footer__left {
  width: 260px;
}
@media all and (max-width: 599px) {
  .footer__left {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
}
.footer__left img {
  width: 185px;
  margin-bottom: 16px;
}
.footer__center {
  background-color: #eae7e3;
  border-radius: 8px;
  padding: 20px;
  width: 240px;
}
@media all and (max-width: 599px) {
  .footer__center {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
}
.footer__center h3 {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 8px;
}
@media all and (max-width: 599px) {
  .footer__center h3 {
    width: 100%;
  }
}
.footer__center ul {
  gap: 5px 10px;
}
@media all and (max-width: 599px) {
  .footer__center ul {
    width: 150px;
  }
}
.footer__center li {
  font-size: 13px;
}
.footer__address {
  font-family: "Kiwi Maru", serif;
  line-height: 160%;
  margin-bottom: 20px;
}
.footer__address a {
  color: #000;
}
.footer__btn-contact {
  max-width: 240px;
  margin-bottom: 20px;
}
@media all and (max-width: 599px) {
  .footer__btn-contact {
    max-width: 100%;
  }
}
.footer__btn-contact a {
  color: #fff;
  display: block;
  text-decoration: none;
  border-radius: 10px;
  font: 500 18px/140% "Kiwi Maru", serif;
  padding: 15px 30px 15px 15px;
  position: relative;
  background: -webkit-gradient(linear, left top, right bottom, from(#ea6626), to(#e38f35));
  background: -webkit-linear-gradient(left top, #ea6626, #e38f35);
  background: -moz-linear-gradient(left top, #ea6626, #e38f35);
  background: linear-gradient(to right bottom, #ea6626, #e38f35);
}
.footer__btn-contact a:after {
  content: "";
  background: url(../images/arrow01.png) no-repeat left center;
  background-size: 22px;
  width: 22px;
  height: 20px;
  position: absolute;
  right: 15px;
  top: calc(50% - 11px);
}
@media all and (max-width: 599px) {
  .footer__btn-contact a:after {
    background-size: 11px;
    width: 11px;
  }
}
@media all and (max-width: 599px) {
  .footer__btn-contact a {
    text-align: center;
  }
}
.footer__google {
  font-size: 12px;
  padding-right: 10px;
}
@media all and (max-width: 599px) {
  .footer__google {
    padding: 0;
  }
}
.footer__houseoftheyear img {
  width: 190px;
}
@media all and (max-width: 599px) {
  .footer__houseoftheyear {
    width: calc(100% - 150px);
    text-align: center;
  }
  .footer__houseoftheyear img {
    width: 110px;
  }
}
.footer__right {
  width: calc(100% - 500px);
  padding-left: 30px;
}
@media all and (max-width: 599px) {
  .footer__right {
    width: 100%;
    padding: 0;
  }
}
.footer__nav {
  gap: 7px 15px;
  margin: 20px 0;
}
@media all and (max-width: 599px) {
  .footer__nav {
    margin-top: 20px;
  }
}
.footer__nav li {
  font-family: "Kiwi Maru", serif;
  font-size: 15px;
}
.footer__nav a {
  color: #333;
}
.footer__nav a:hover {
  text-decoration: underline;
}
.footer__copy {
  color: #858585;
  font-size: 15px;
  text-align: right;
}
@media all and (max-width: 599px) {
  .footer__copy {
    text-align: center;
  }
}

.fb-page iframe {
  width: 100%;
}

.grecaptcha-badge {
  visibility: hidden;
}

.home .header {
  background: none;
  height: 857px;
}
@media all and (max-width: 820px) {
  .home .header {
    height: 680px;
  }
}
@media all and (max-width: 599px) {
  .home .header {
    background: url(../images/top/bg-main-sp.jpg) repeat;
    height: auto;
    margin-bottom: 30px;
  }
}
.home .header__inner {
  position: relative;
  z-index: 1999;
}
.home .topimage {
  background: url(../images/top/bg01.png) no-repeat top center;
  width: 100%;
  z-index: 100;
  position: absolute;
  top: 0;
  margin: auto;
  height: 857px;
  padding-top: 61px;
  text-align: center;
}
@media all and (max-width: 820px) {
  .home .topimage {
    height: 520px;
  }
}
@media all and (max-width: 599px) {
  .home .topimage {
    background: none;
    position: relative;
    height: auto;
    padding: 0;
  }
}
.home .topimage__copy {
  font: 700 6.4vw/160% "Kiwi Maru", serif;
  text-align: left;
  position: absolute;
  bottom: 20px;
  left: 15px;
  letter-spacing: 0;
  text-shadow: 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff;
  transition: all 0.2s;
}
.home .topimage__marker {
  background: -webkit-linear-gradient(transparent 60%, #ffdd5f 60%);
  background: -o-linear-gradient(transparent 60%, #ffdd5f 60%);
  background: linear-gradient(transparent 60%, #ffdd5f 60%);
}
.home .bg {
  width: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  margin: auto;
}
@media all and (max-width: 599px) {
  .home .bg {
    display: none;
  }
}
.home .t_cell {
  height: 857px;
  width: 50%;
}
.home .t_cell.left {
  background: url(../images/top/bg02.png) repeat-x top left;
}
.home .t_cell.right {
  background: url(../images/top/bg03.png) repeat-x top left;
}
@media all and (max-width: 820px) {
  .home .t_cell {
    display: none;
  }
}

#balloon {
  background: url(../images/balloon.png) no-repeat;
  width: 96px;
  height: 195px;
  position: absolute;
  z-index: 200;
  right: 0;
  top: 130px;
}
@media all and (max-width: 599px) {
  #balloon {
    display: none;
  }
}

#sun {
  background: url(../images/sun.gif) no-repeat;
  width: 176px;
  height: 189px;
  position: absolute;
  z-index: 200;
  left: 27px;
  top: 100px;
}
@media all and (max-width: 599px) {
  #sun {
    display: none;
  }
}

#cloud1 {
  background: url(../images/cloud1.png) no-repeat;
  width: 70px;
  height: 35px;
  position: absolute;
  z-index: 200;
  right: 190px;
  top: 170px;
}
@media all and (max-width: 599px) {
  #cloud1 {
    display: none;
  }
}

#cloud2 {
  background: url(../images/cloud2.png) no-repeat;
  width: 59px;
  height: 35px;
  position: absolute;
  z-index: 200;
  right: 385px;
  top: 170px;
}
@media all and (max-width: 599px) {
  #cloud2 {
    display: none;
  }
}

#cloud3 {
  background: url(../images/cloud3.png) no-repeat;
  width: 77px;
  height: 75px;
  position: absolute;
  z-index: 200;
  right: 550px;
  top: 150px;
}
@media all and (max-width: 599px) {
  #cloud3 {
    display: none;
  }
}

.top-menu {
  margin: -150px 0 80px;
}
@media all and (max-width: 599px) {
  .top-menu {
    margin: 0 0 30px;
  }
}
.top-menu__inner {
  position: relative;
  z-index: 200;
  gap: 10px;
  -webkit-justify-content: center;
  justify-content: center;
}
.top-menu li {
  width: 319px;
}
@media all and (max-width: 820px) {
  .top-menu li {
    width: calc(33.3333333333% - 7px);
  }
}
@media all and (max-width: 599px) {
  .top-menu li {
    width: 100%;
  }
}
.top-menu li img {
  width: 100%;
}

.top-catch {
  overflow: hidden;
  margin-bottom: 60px;
}
.top-catch__inner {
  position: relative;
}
.top-catch__heading {
  font: 500 50px/140% "Kiwi Maru", serif;
  text-align: center;
  margin-bottom: 25px;
}
@media all and (max-width: 599px) {
  .top-catch__heading {
    font-size: 32px;
  }
}
.top-catch__paragraph {
  font: 500 19px/160% "Kiwi Maru", serif;
  text-align: center;
  letter-spacing: 0;
  margin-bottom: 20px;
}
@media all and (max-width: 820px) {
  .top-catch__paragraph {
    font-size: 2vw;
  }
}
@media all and (max-width: 599px) {
  .top-catch__paragraph {
    font-size: 17px;
    text-align: left;
    margin-bottom: 130px;
  }
}
.top-catch__zeh {
  text-align: center;
}
.top-catch__zeh a {
  color: #fff;
  display: inline-block;
  text-decoration: none;
  border-radius: 10px;
  font: 500 22px/140% "Kiwi Maru", serif;
  padding: 15px 50px 15px 35px;
  position: relative;
  background: -webkit-gradient(linear, left top, right bottom, from(#ea6626), to(#e38f35));
  background: -webkit-linear-gradient(left top, #ea6626, #e38f35);
  background: -moz-linear-gradient(left top, #ea6626, #e38f35);
  background: linear-gradient(to right bottom, #ea6626, #e38f35);
}
.top-catch__zeh a:after {
  content: "";
  background: url(../images/arrow01.png) no-repeat left center;
  background-size: 20px;
  width: 22px;
  height: 20px;
  position: absolute;
  right: 15px;
  top: calc(50% - 9px);
}
@media all and (max-width: 599px) {
  .top-catch__zeh a:after {
    background-size: 11px;
    width: 11px;
  }
}
@media all and (max-width: 820px) {
  .top-catch__zeh a {
    font-size: 20px;
  }
}
.top-catch__zeh a:hover {
  opacity: 0.7;
}
.top-catch__house01 {
  position: absolute;
  bottom: 0;
  left: -100px;
  z-index: 9999;
  width: 200px;
}
@media all and (max-width: 599px) {
  .top-catch__house01 {
    width: 140px;
    left: 15px;
    bottom: 100px;
  }
}
.top-catch__house02 {
  position: absolute;
  bottom: 30px;
  right: -100px;
  z-index: 9999;
  width: 230px;
}
@media all and (max-width: 599px) {
  .top-catch__house02 {
    width: 160px;
    right: 15px;
    bottom: 110px;
  }
}

.top-works__heading,
.top-events__heading {
  font: 500 40px/100% "Kiwi Maru", serif;
  text-align: center;
  margin-bottom: 50px;
}
.top-works__inner,
.top-events__inner {
  gap: 10px;
  margin-bottom: 30px;
}
.top-works__block,
.top-events__block {
  width: calc(33.3333333333% - 10px);
}
@media all and (max-width: 599px) {
  .top-works__block,
  .top-events__block {
    width: 100%;
    margin-bottom: 20px;
  }
  .top-works__block:last-child,
  .top-events__block:last-child {
    margin: 0;
  }
}
.top-works__block a,
.top-events__block a {
  text-decoration: none;
}
.top-works__thumb,
.top-events__thumb {
  margin-bottom: 10px;
}
.top-works__btn,
.top-events__btn {
  text-align: center;
}
.top-works__btn a,
.top-events__btn a {
  display: inline-block;
  text-decoration: none;
  padding: 16px 40px 14px 30px;
  border-radius: 5px;
  font-weight: bold;
}

.top-works {
  margin-bottom: 60px;
}
.top-works .marker {
  background: -webkit-linear-gradient(transparent 80%, #ffdd5f 80%);
  background: -o-linear-gradient(transparent 80%, #ffdd5f 80%);
  background: linear-gradient(transparent 80%, #ffdd5f 80%);
}
.top-works__thumb a {
  position: relative;
}
.top-works__thumb a:after {
  content: "もっと見る";
  position: absolute;
  bottom: 0;
  right: 0;
  color: #333;
  background: url(../images/arrow04.png) no-repeat right 5px center #ffdd60;
  background-size: 11px;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 25px 5px 13px;
}
.top-works__btn a {
  color: #333;
  background: url(../images/arrow04.png) no-repeat right 10px center #ffdd60;
  background-size: 11px;
}

.top-events {
  margin-bottom: 60px;
}
.top-events .marker {
  background: -webkit-linear-gradient(transparent 80%, #a3dbff 80%);
  background: -o-linear-gradient(transparent 80%, #a3dbff 80%);
  background: linear-gradient(transparent 80%, #a3dbff 80%);
}
.top-events__ttl {
  font: 500 20px/150% "Kiwi Maru", serif;
}
.top-events__btn a {
  color: #fff;
  background: url(../images/arrow01.png) no-repeat right 10px center #4bb1f3;
  background-size: 11px;
}

.top-bnr {
  margin-bottom: 50px;
}
.top-bnr__inner {
  gap: 10px;
  -webkit-justify-content: center;
  justify-content: center;
}
@media all and (max-width: 820px) {
  .top-bnr li {
    width: calc(33.3333333333% - 7px);
  }
}
@media all and (max-width: 599px) {
  .top-bnr li {
    width: 100%;
  }
}