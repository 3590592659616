@import "_common";

.home{
    .header {
        background: none;
        height: 857px;

        @media all and ( $tab ){
            height: 680px;
        }

        @media all and ( $sm ){
            background: url(../images/top/bg-main-sp.jpg) repeat;
            height: auto;
            margin-bottom: 30px;
        }

        &__inner{
            position: relative;
            z-index: 1999;
        }
    }

    .topimage {
        background: url(../images/top/bg01.png) no-repeat top center;
        width: 100%;
        z-index: 100;
        position: absolute;
        top: 0;
        margin: auto;
        height: 857px;
        padding-top: 61px;
        text-align: center;

        @media all and ( $tab ){
            height: 520px;
        }

        @media all and ( $sm ){
            background: none;
            position: relative;
            height: auto;
            padding: 0;
        }

        &__copy{
            font: 700 6.4vw/160% $font_kiwi;
            text-align: left;
            position: absolute;
            bottom: 20px;
            left: 15px;
            letter-spacing: 0;
            text-shadow:
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff;
            transition: all .2s;
        }

        &__marker{
            background: -webkit-linear-gradient(transparent 60%, #ffdd5f 60%);
            background: -o-linear-gradient(transparent 60%, #ffdd5f 60%);
            background: linear-gradient(transparent 60%, #ffdd5f 60%);
        }
    }

    .bg {
        width: 100%;
        z-index: 1;
        position: absolute;
        top: 0;
        margin: auto;

        @media all and ( $sm ){
            display: none;
        }
    }

    .t_cell {
        height: 857px;
        width: 50%;

        &.left {
            background: url(../images/top/bg02.png) repeat-x top left;
        }

        &.right {
            background: url(../images/top/bg03.png) repeat-x top left;
        }

        @media all and ( $tab ){
            display: none;
        }
    }
}

#balloon {
    background: url(../images/balloon.png) no-repeat;
    width: 96px;
    height: 195px;
    position: absolute;
    z-index: 200;
    right: 0;
    top: 130px;

    @media all and ( $sm ){
        display: none;
    }
}

#sun {
    background: url(../images/sun.gif) no-repeat;
    width: 176px;
    height: 189px;
    position: absolute;
    z-index: 200;
    left: 27px;
    top: 100px;

    @media all and ( $sm ){
        display: none;
    }
}

#cloud1 {
    background: url(../images/cloud1.png) no-repeat;
    width: 70px;
    height: 35px;
    position: absolute;
    z-index: 200;
    right: 190px;
    top: 170px;

    @media all and ( $sm ){
        display: none;
    }
}

#cloud2 {
    background: url(../images/cloud2.png) no-repeat;
    width: 59px;
    height: 35px;
    position: absolute;
    z-index: 200;
    right: 385px;
    top: 170px;

    @media all and ( $sm ){
        display: none;
    }
}

#cloud3 {
    background: url(../images/cloud3.png) no-repeat;
    width: 77px;
    height: 75px;
    position: absolute;
    z-index: 200;
    right: 550px;
    top: 150px;

    @media all and ( $sm ){
        display: none;
    }
}

.top-menu{
    margin: -150px 0 80px;

    @media all and ( $sm ){
        margin: 0 0 30px;
    }

    &__inner{
        position: relative;
        z-index: 200;
        gap: 10px;
        -webkit-justify-content: center;
        justify-content: center;
    }

    li{
        width: 319px;

        @media all and ( $tab ){
            width: calc(100% / 3 - 7px);
        }

        @media all and ( $sm ){
            width: 100%;
        }

        img{
            width: 100%;
        }
    }
}

.top-catch{
    overflow: hidden;
    margin-bottom: 60px;

    &__inner{
        position: relative;
    }

    &__heading{
        font: 500 50px/140% $font_kiwi;
        text-align: center;
        margin-bottom: 25px;

        @media all and ( $sm ){
            font-size: 32px;
        }
    }

    &__paragraph{
        font: 500 19px/160% $font_kiwi;
        text-align: center;
        letter-spacing: 0;
        margin-bottom: 20px;

        @media all and ( $tab ){
            font-size: 2vw;
        }

        @media all and ( $sm ){
            font-size: 17px;
            text-align: left;
            margin-bottom: 130px;
        }
    }

    &__zeh{
        text-align: center;

        a {
            color: #fff;
            display: inline-block;
            text-decoration: none;
            border-radius: 10px;
            font: 500 22px/140% $font_kiwi;
            padding: 15px 50px 15px 35px;
            position: relative;
            background:-webkit-gradient(linear, left top, right bottom, from(#ea6626), to(#e38f35));
            background:-webkit-linear-gradient(left top, #ea6626, #e38f35);
            background:-moz-linear-gradient(left top, #ea6626, #e38f35);
            background:linear-gradient(to right bottom, #ea6626, #e38f35);

            &:after{
                content: "";
                background: url(../images/arrow01.png) no-repeat left center;
                background-size: 20px;
                width: 22px;
                height: 20px;
                position: absolute;
                right: 15px;
                top: calc(50% - 9px);

                @media all and ( $sm ){
                    background-size: 11px;
                    width: 11px;
                }
            }

            @media all and ( $tab ){
                font-size: 20px;
            }

            &:hover{
                opacity: .7;
            }
        }
    }

    &__house01 {
        position: absolute;
        bottom: 0;
        left: -100px;
        z-index: 9999;
        width: 200px;

        @media all and ( $sm ){
            width: 140px;
            left: 15px;
            bottom: 100px;
        }
    }

    &__house02 {
        position: absolute;
        bottom: 30px;
        right: -100px;
        z-index: 9999;
        width: 230px;

        @media all and ( $sm ){
            width: 160px;
            right: 15px;
            bottom: 110px;
        }
    }
}

.top-works,
.top-events{
    &__heading{
        font: 500 40px/100% $font_kiwi;
        text-align: center;
        margin-bottom: 50px;
    }

    &__inner{
        gap: 10px;
        margin-bottom: 30px;
    }

    &__block{
        width: calc(100% / 3 - 10px);

        @media all and ( $sm ){
            width: 100%;
            margin-bottom: 20px;

            &:last-child{
                margin: 0;
            }
        }

        a{
            text-decoration: none;
        }
    }

    &__thumb{
        margin-bottom: 10px;
    }

    &__btn{
        text-align: center;

        a{
            display: inline-block;
            text-decoration: none;
            padding: 16px 40px 14px 30px;
            border-radius: 5px;
            font-weight: bold;
        }
    }
}

.top-works{
    margin-bottom: 60px;

    .marker{
        background: -webkit-linear-gradient(transparent 80%, #ffdd5f 80%);
        background: -o-linear-gradient(transparent 80%, #ffdd5f 80%);
        background: linear-gradient(transparent 80%, #ffdd5f 80%);
    }

    &__thumb{
        a{
            position: relative;

            &:after{
                content: "もっと見る";
                position: absolute;
                bottom: 0;
                right: 0;
                color: $c_black;
                background: url(../images/arrow04.png) no-repeat right 5px center #ffdd60;
                background-size: 11px;
                font-size: 14px;
                font-weight: bold;
                padding: 5px 25px 5px 13px;
            }
        }
    }

    &__btn{
        a{
            color: $c_black;
            background: url(../images/arrow04.png) no-repeat right 10px center #ffdd60;
            background-size: 11px;
        }
    }
}

.top-events{
    margin-bottom: 60px;

    .marker{
        background: -webkit-linear-gradient(transparent 80%, #a3dbff 80%);
        background: -o-linear-gradient(transparent 80%, #a3dbff 80%);
        background: linear-gradient(transparent 80%, #a3dbff 80%);
    }

    &__ttl{
        font: 500 20px/150% $font_kiwi;
    }

    &__btn{
        a{
            color: #fff;
            background: url(../images/arrow01.png) no-repeat right 10px center #4bb1f3;
            background-size: 11px;
        }
    }
}

.top-bnr{
    margin-bottom: 50px;

    &__inner{
        gap: 10px;
        -webkit-justify-content: center;
        justify-content: center;
    }

    @media all and ( $tab ){
        li{
            width: calc(100% / 3 - 7px);
        }
    }

    @media all and ( $sm ){
        li{
            width: 100%;
        }
    }
}