@import url('https://fonts.googleapis.com/css2?family=Kiwi+Maru:wght@500&display=swap');
@import "_reset";

$font_a: arial,
Verdana;
$font_s: "ヒラギノ明朝 Pro W3",
"Hiragino Mincho Pro",
serif,
"ＭＳ Ｐ明朝",
"MS PMincho";
$font_g: "Noto Sans JP",
"ヒラギノ角ゴ Pro W3",
"Hiragino Kaku Gothic Pro",
Verdana,
"メイリオ",
Meiryo,
Osaka,
"Avenir Next",
"游ゴシック",
"Yu Gothic",
sans-serif;
$font_kiwi: 'Kiwi Maru',
serif;

$xs: "max-width: 320px"; // iPhone5s
$sm: "max-width: 599px"; // iPhone6, iPhone6 Plus
$tab: "max-width: 820px"; // Tablet
$lap: "max-width: 1040px"; // Desktop
$lag: "min-width: 1041px";

$c_black: #333;
$c_orange: #e38f35;

* {
    box-sizing: border-box;

    &:focus {
        outline: none;
    }
}

body {
    background: url(../images/bg-main.jpg) repeat top left;
}

body,
p,
ul,
li,
dl,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
address {
    color: $c_black;
    font: 400 normal 16px/166% $font_g;
    letter-spacing: 1px;
    -moz-font-feature-settings: "palt";
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    margin: 0;
}

a {
    color: $c_orange;
    text-decoration: underline;
    transition: all 0.2s;
    -webkit-tap-highlight-color:rgba(0,0,0,0);

    &:hover {
        text-decoration: none;
        opacity: .7;
    }
}

.t_left {
    text-align: left !important;
}

.t_right {
    text-align: right !important;
}

.t_center {
    text-align: center !important;
}

.view_pc{
    @media all and ( $sm ){
        display: none;
    }
}

.view_sp{
    display: none;

    @media all and ( $sm ){
        display: block;
    }
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    &.wrap {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    &.center {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.container{
    width: 1000px;
    max-width: 100%;
    margin: 0 auto;

    @media all and ( $tab ){
        padding: 0 15px;
    }
}

// header
.header {
    background: url(../images/bg-head.png) repeat-x top -20px left;

    @media all and ( $tab ){
        margin-bottom: 20px;

        &__logo{
            width: 160px;
        }
    }

    &__inner{
        -webkit-justify-content: space-between;
        justify-content: space-between;
        gap: 10px;
        padding: 15px 0;

        @media all and ( $tab ){
            padding: 15px;
        }
    }

    &__menu{
        gap: 0 16px;

        a{
            display: block;
            height: 110px;
            overflow: hidden;

            &:hover{
                opacity: 1;

                img{
                    opacity: 1;
                    margin-top: -110px;
                }
            }
        }
    }

    &__info{
        width: 278px;
    }

    &__info-txt{
        font-size: 13px;
        margin-bottom: 4px;
        text-align: center;
    }

    &__tel{
        margin-bottom: 5px;
    }

    &__time{
        font-size: 13px;
        text-align: center;
    }

    &__nav{
        margin: -10px 0 10px;
        animation-duration: 0.1s;

        @media all and ( $tab ){
            display: none;
            background-color: rgba($color: #c2a364, $alpha: .9);
            position: fixed;
            top: 0;
            left: 0;
            margin: 0;
            width: 100%;
            height: 100vh;
            padding-top: 80px;
            z-index: 1000;
        }
    }

    &__nav-inner{
        background: url(../images/bg-nav.png) no-repeat center center;
        background-size: cover;
        width: 1040px;
        height: 68px;
        gap: 20px 30px;
        -webkit-justify-content: center;
        justify-content: center;

        @media all and ( $tab ){
            background: none;
        }
    }

    .menu-item{
        a{
            color: $c_black;
            font-family: $font_kiwi;
            font-size: 18px;
            letter-spacing: 0;
            text-decoration: none;
            text-shadow:
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff,
            0 0 2px #fff;
            transition: all .2s;

            &:hover{
                opacity: 1;
                background: -webkit-linear-gradient(transparent 80%, $c_orange 80%);
                background: -o-linear-gradient(transparent 80%, $c_orange 80%);
                background: linear-gradient(transparent 80%, $c_orange 80%);
            }
        }

        &.current-menu-item{
            a{
                background: -webkit-linear-gradient(transparent 80%, $c_orange 80%);
                background: -o-linear-gradient(transparent 80%, $c_orange 80%);
                background: linear-gradient(transparent 80%, $c_orange 80%);
            }
        }
    }
}

#menuButton{
    display: block;
    height: 40px;
    position: relative;
    z-index: 1010;
    border: solid 2px $c_black;
    border-radius: 3px;
    text-decoration: none;
    color: $c_black;
    font: 700 16px/38px $font_a;
    padding: 0 40px 0 10px;
    outline: none;

    &:hover{
        opacity: 1;
    }

    span{
        display: block;
        background: $c_black;
        width: 24px;
        height: 2px;
        border-radius: 2px;
        position: absolute;
        right: 9px;
        transition: all 0.4s;

        &:first-child{
            top: 10px;
        }

        &:nth-child(2){
            margin-top: -1px;
            top: 50%;
        }

        &:last-child{
            bottom: 10px;
        }
    }

    &.active{
        color: #fff;
        border-color: #fff;

        span{
            background: #fff;

            &:first-child{
                -webkit-transform: translateY(8px) rotate(45deg);
                -moz-transform: translateY(8px) rotate(45deg);
                transform: translateY(8px) rotate(45deg);
                top: 9px;
            }
            &:nth-child(2){
                opacity: 0;
            }
            &:last-child{
                -webkit-transform: translateY(-8px) rotate(-45deg);
                -moz-transform: translateY(-8px) rotate(-45deg);
                transform: translateY(-8px) rotate(-45deg);
                bottom: 9px;
            }
        }
    }
}

// footer
#pagetop_button {
    position: fixed;
    right: 20px;
    bottom: 30px;
    z-index: 9999;

    @media all and ( $lap ){
        display: none!important;
    }

    @media all and ( $sm ){
        display: none!important;
    }
}

.footer {
    background: url(../images/bg-foot.png) repeat-x left top;
    padding: 100px 0 30px;

    &__inner{
        -webkit-justify-content: center;
        justify-content: center;
    }

    &__left {
        width: 260px;

        @media all and ( $sm ){
            width: 100%;
            text-align: center;
            margin-bottom: 20px;
        }

        img {
            width: 185px;
            margin-bottom: 16px;
        }
    }

    &__center {
        background-color: #eae7e3;
        border-radius: 8px;
        padding: 20px;
        width: 240px;

        @media all and ( $sm ){
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            margin-bottom: 20px;
        }

        h3 {
            font-size: 17px;
            font-weight: bold;
            margin-bottom: 8px;

            @media all and ( $sm ){
                width: 100%;
            }
        }

        ul{
            gap: 5px 10px;

            @media all and ( $sm ){
                width: 150px;
            }
        }

        li {
            font-size: 13px;
        }
    }

    &__address{
        font-family: $font_kiwi;
        line-height: 160%;
        margin-bottom: 20px;

        a {
            color: #000;
        }
    }

    &__btn-contact{
        max-width: 240px;
        margin-bottom: 20px;

        @media all and ( $sm ){
            max-width: 100%;
        }

        a{
            color: #fff;
            display: block;
            text-decoration: none;
            border-radius: 10px;
            font: 500 18px/140% $font_kiwi;
            padding: 15px 30px 15px 15px;
            position: relative;
            background:-webkit-gradient(linear, left top, right bottom, from(#ea6626), to(#e38f35));
            background:-webkit-linear-gradient(left top, #ea6626, #e38f35);
            background:-moz-linear-gradient(left top, #ea6626, #e38f35);
            background:linear-gradient(to right bottom, #ea6626, #e38f35);

            &:after{
                content: "";
                background: url(../images/arrow01.png) no-repeat left center;
                background-size: 22px;
                width: 22px;
                height: 20px;
                position: absolute;
                right: 15px;
                top: calc(50% - 11px);

                @media all and ( $sm ){
                    background-size: 11px;
                    width: 11px;
                }
            }

            @media all and ( $sm ){
                text-align: center;
            }
        }
    }

    &__google{
        font-size: 12px;
        padding-right: 10px;

        @media all and ( $sm ){
            padding: 0;
        }
    }

    &__houseoftheyear{
        img{
            width: 190px;
        }

        @media all and ( $sm ){
            width: calc(100% - 150px);
            text-align: center;

            img{
                width: 110px;
            }
        }
    }

    &__right{
        width: calc(100% - 500px);
        padding-left: 30px;

        @media all and ( $sm ){
            width: 100%;
            padding: 0;
        }
    }

    &__nav{
        gap: 7px 15px;
        margin: 20px 0;

        @media all and ( $sm ){
            margin-top: 20px;
        }

        li{
            font-family: $font_kiwi;
            font-size: 15px;
        }

        a{
            color: $c_black;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__copy {
        color: #858585;
        font-size: 15px;
        text-align: right;

        @media all and ( $sm ){
            text-align: center;
        }
    }
}

.fb-page {
    iframe{
        width: 100%;
    }
}

.grecaptcha-badge { visibility: hidden; }